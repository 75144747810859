var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"footer-alt"},[_c('div',{staticClass:"container"},[_c('img',{staticClass:"logo mt-5 mb-2",attrs:{"src":require("../../assets/img/small-logo.png"),"alt":"logo"}}),_c('div',{staticClass:"col-12 footer-bg pb-5 row"},[_c('div',{staticClass:"col-12 col-md-4 col-lg-4"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("ihgp"))+" ")]),_c('p',{staticClass:"mb-2"},[_vm._v("Oranienstr. 155")]),_c('p',[_vm._v("10969 Berlin")]),_vm._m(0),_c('div',{staticClass:"d-flex contact-icon mt-5"},[_c('a',{attrs:{"href":"https://www.linkedin.com/company/flinktax/","target":"_blank"}},[_c('font-awesome-icon',{staticClass:"mr-3",attrs:{"icon":_vm.faLinkedin}})],1),_c('a',{attrs:{"href":"https://instagram.com/flinktax?igshid=MzRlODBiNWFlZA==","target":"_blank"}},[_c('font-awesome-icon',{staticClass:"mr-3",attrs:{"icon":_vm.faInstagramSquare}})],1),_c('a',{attrs:{"href":"https://twitter.com/flinktax","target":"_blank"}},[_c('img',{attrs:{"src":require('../../assets/img/icon/x-twitter.svg'),"alt":"twitter"}})])])]),_c('div',{staticClass:"col-12 col-md-3 col-lg-3 footer-menu"},[_c('ul',[_c('li',[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ el: '#howWorks', offset: -200 }),expression:"{ el: '#howWorks', offset: -200 }"}],attrs:{"href":`https://flinktax.de/${
                  _vm.$i18n.locale === 'tr' ? '' : _vm.$i18n.locale
                }/#Steuererklrung`}},[_c('p',[_vm._v(_vm._s(_vm.$t("howWorks")))])])]),_c('li',[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ el: '#price', offset: -200 }),expression:"{ el: '#price', offset: -200 }"}],attrs:{"href":`https://flinktax.de/${
                  _vm.$i18n.locale === 'tr' ? '' : _vm.$i18n.locale
                }/#Kosten`}},[_c('p',[_vm._v(_vm._s(_vm.$t("costs")))])])]),_c('li',[_c('a',{attrs:{"href":`https://flinktax.de/${
                  _vm.$i18n.locale === 'tr' ? '' : _vm.$i18n.locale
                }/about`,"target":"_blank"}},[_c('p',[_vm._v(_vm._s(_vm.$t("about")))])])]),_c('li',[_c('a',{attrs:{"href":`https://flinktax.de/${
                  _vm.$i18n.locale === 'tr' ? '' : _vm.$i18n.locale
                }/steuertipps`,"target":"_blank"}},[_c('p',[_vm._v(_vm._s(_vm.$t("taxGuide")))])])])])]),_c('div',{staticClass:"col-12 col-md-2 col-lg-2 footer-menu"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":`https://flinktax.de/${
                  _vm.$i18n.locale === 'tr' ? '' : _vm.$i18n.locale
                }/faq`,"target":"_blank"}},[_c('p',[_vm._v(_vm._s(_vm.$t("faq")))])])]),_c('li',[_c('a',{attrs:{"href":`https://flinktax.de/${
                  _vm.$i18n.locale === 'tr' ? '' : _vm.$i18n.locale
                }/support`,"target":"_blank"}},[_c('p',[_vm._v(_vm._s(_vm.$t("support")))])])]),_c('li',[_c('a',{attrs:{"href":`https://flinktax.de/${
                  _vm.$i18n.locale === 'tr' ? '' : _vm.$i18n.locale
                }/kontakt`,"target":"_blank"}},[_c('p',[_vm._v(_vm._s(_vm.$t("contact")))])])]),_c('li',[_c('a',{attrs:{"href":`https://flinktax.de/${
                  _vm.$i18n.locale === 'tr' ? '' : _vm.$i18n.locale
                }/karriere`,"target":"_blank"}},[_c('p',[_vm._v(_vm._s(_vm.$t("career")))])])])])]),_c('div',{staticClass:"col-12 colmd-3 col-lg-3 footer-menu"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":`https://flinktax.de/${
                  _vm.$i18n.locale === 'tr' ? '' : _vm.$i18n.locale
                }/agb`,"target":"_blank"}},[_c('p',[_vm._v(_vm._s(_vm.$t("agb")))])])]),_c('li',[_c('a',{attrs:{"href":`https://flinktax.de/${
                    _vm.$i18n.locale === 'tr' ? '' : _vm.$i18n.locale
                  }/privacy` - _vm.policy,"target":"_blank"}},[_c('p',[_vm._v(_vm._s(_vm.$t("privacyPolicy")))])])]),_c('li',[_c('a',{attrs:{"href":`https://flinktax.de/${
                  _vm.$i18n.locale === 'tr' ? '' : _vm.$i18n.locale
                }/partner`,"target":"_blank"}},[_c('p',[_vm._v(_vm._s(_vm.$t("partnerShip")))])])]),_c('li',[_c('a',{attrs:{"href":`https://flinktax.de/${
                  _vm.$i18n.locale === 'tr' ? '' : _vm.$i18n.locale
                }/impressum`,"target":"_blank"}},[_c('p',[_vm._v(_vm._s(_vm.$t("imprint")))])])])])])])]),_c('div',{staticClass:"d-flex border-top pt-2 pb-2"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"font-size-15 mb-0"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" Flinktax. ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt-5"},[_vm._v(" E-Mail: "),_c('a',{attrs:{"href":"mailto:mail@flinktax.de"}},[_vm._v("mail@flinktax.de")])])
}]

export { render, staticRenderFns }