<template>
  <div>
    <!-- Footer Start -->
    <div class="footer-alt">
      <div class="container">
        <img
          src="../../assets/img/small-logo.png"
          alt="logo"
          class="logo mt-5 mb-2"
        />
        <div class="col-12 footer-bg pb-5 row">
          <div class="col-12 col-md-4 col-lg-4">
            <p class="font-weight-bold">
              {{ $t("ihgp") }}
            </p>
            <p class="mb-2">Oranienstr. 155</p>
            <p>10969 Berlin</p>
            <p class="mt-5">
              E-Mail:
              <a href="mailto:mail@flinktax.de">mail@flinktax.de</a>
            </p>
            <div class="d-flex contact-icon mt-5">
              <a
                href="https://www.linkedin.com/company/flinktax/"
                target="_blank"
              >
                <font-awesome-icon :icon="faLinkedin" class="mr-3" />
              </a>
              <a
                href="https://instagram.com/flinktax?igshid=MzRlODBiNWFlZA=="
                target="_blank"
              >
                <font-awesome-icon :icon="faInstagramSquare" class="mr-3" />
              </a>
              <a href="https://twitter.com/flinktax" target="_blank">
                <!-- <font-awesome-icon :icon="faTwitter" /> -->
                <img
                  :src="require('../../assets/img/icon/x-twitter.svg')"
                  alt="twitter"
                />
              </a>
            </div>
          </div>
          <div class="col-12 col-md-3 col-lg-3 footer-menu">
            <ul>
              <li>
                <a
                  :href="`https://flinktax.de/${
                    $i18n.locale === 'tr' ? '' : $i18n.locale
                  }/#Steuererklrung`"
                  v-scroll-to="{ el: '#howWorks', offset: -200 }"
                >
                  <p>{{ $t("howWorks") }}</p>
                </a>
              </li>
              <li>
                <a
                  :href="`https://flinktax.de/${
                    $i18n.locale === 'tr' ? '' : $i18n.locale
                  }/#Kosten`"
                  v-scroll-to="{ el: '#price', offset: -200 }"
                >
                  <p>{{ $t("costs") }}</p>
                </a>
              </li>
              <li>
                <a
                  :href="`https://flinktax.de/${
                    $i18n.locale === 'tr' ? '' : $i18n.locale
                  }/about`"
                  target="_blank"
                >
                  <p>{{ $t("about") }}</p>
                </a>
              </li>
              <li>
                <a
                  :href="`https://flinktax.de/${
                    $i18n.locale === 'tr' ? '' : $i18n.locale
                  }/steuertipps`"
                  target="_blank"
                >
                  <p>{{ $t("taxGuide") }}</p>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-2 col-lg-2 footer-menu">
            <ul>
              <li>
                <a
                  :href="`https://flinktax.de/${
                    $i18n.locale === 'tr' ? '' : $i18n.locale
                  }/faq`"
                  target="_blank"
                >
                  <p>{{ $t("faq") }}</p>
                </a>
              </li>
              <li>
                <a
                  :href="`https://flinktax.de/${
                    $i18n.locale === 'tr' ? '' : $i18n.locale
                  }/support`"
                  target="_blank"
                >
                  <p>{{ $t("support") }}</p>
                </a>
              </li>
              <li>
                <a
                  :href="`https://flinktax.de/${
                    $i18n.locale === 'tr' ? '' : $i18n.locale
                  }/kontakt`"
                  target="_blank"
                >
                  <p>{{ $t("contact") }}</p>
                </a>
              </li>
              <li>
                <a
                  :href="`https://flinktax.de/${
                    $i18n.locale === 'tr' ? '' : $i18n.locale
                  }/karriere`"
                  target="_blank"
                >
                  <p>{{ $t("career") }}</p>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-12 colmd-3 col-lg-3 footer-menu">
            <ul>
              <li>
                <a
                  :href="`https://flinktax.de/${
                    $i18n.locale === 'tr' ? '' : $i18n.locale
                  }/agb`"
                  target="_blank"
                >
                  <p>{{ $t("agb") }}</p>
                </a>
              </li>
              <li>
                <a
                  :href="
                    `https://flinktax.de/${
                      $i18n.locale === 'tr' ? '' : $i18n.locale
                    }/privacy` - policy
                  "
                  target="_blank"
                >
                  <p>{{ $t("privacyPolicy") }}</p>
                </a>
              </li>
              <li>
                <a
                  :href="`https://flinktax.de/${
                    $i18n.locale === 'tr' ? '' : $i18n.locale
                  }/partner`"
                  target="_blank"
                >
                  <p>{{ $t("partnerShip") }}</p>
                </a>
              </li>
              <li>
                <a
                  :href="`https://flinktax.de/${
                    $i18n.locale === 'tr' ? '' : $i18n.locale
                  }/impressum`"
                  target="_blank"
                >
                  <p>{{ $t("imprint") }}</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="d-flex border-top pt-2 pb-2">
        <div class="col-lg-12">
          <div class="text-center">
            <p class="font-size-15 mb-0">
              © {{ new Date().getFullYear() }} Flinktax.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer End -->
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faLinkedin,
  faTwitter,
  faFacebookF,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";

export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faLinkedin,
      faTwitter,
      faFacebookF,
      faInstagramSquare,
    };
  },
};
</script>
