<template>
  <!--Navbar Start-->
  <nav
    class="navbar navbar-expand-xl fixed-top navbar-custom sticky sticky-dark"
    id="navbar"
    :class="{ 'navbar-light': navbarcolor === true }"
  >
    <div class="container">
      <!-- LOGO -->
      <router-link class="navbar-brand logo" to="/">
        <img src="@/assets/img/logo.png" alt class="logo-dark" height="24" />
        <img src="@/assets/img/logo.png" alt class="logo-light" height="24" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu()"
        aria-label="Toggle navigation"
        id="navbar-button"
      >
        <i class="mdi mdi-menu"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul
          class="navbar-nav mx-auto navbar-center"
          id="mySidenav"
          v-scroll-spy-active
        >
          <li class="nav-item">
            <router-link
              to="/"
              v-scroll-to="{ el: '#howWorks', offset: -200 }"
              class="nav-link"
              >{{ $t("howWorks") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/"
              v-scroll-to="{ el: '#price', offset: -200 }"
              class="nav-link"
              >{{ $t("costs") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link" target="_blank">{{
              $t("about")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/steuertipps" class="nav-link">{{
              $t("taxGuide")
            }}</router-link>
          </li>
        </ul>
        <ul class="navbar-nav navbar-center">
          <!-- {{activeUser}} -->
          <li class="nav-item d-flex align-items-center lang-select">
            <b-dropdown
              id="dropdown-1"
              :text="`${$t('hi')} ${activeUser.name} !`"
              class="m-md-2 user-dropdown"
              v-if="activeUser.id"
            >
              <b-dropdown-item>
                <router-link
                  :to="
                    activeUser.role === 'User'
                      ? '/informationen'
                      : 'admin/kunden'
                  "
                  class="m-0"
                >
                  <span v-if="activeUser.role === 'User'">
                    {{ $t("account") }}
                  </span>
                  <span v-else>{{ $t("adminPanel") }}</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-divider
                v-if="activeUser.role === 'User'"
              ></b-dropdown-divider>
              <b-dropdown-item v-if="activeUser.role === 'User'">
                <router-link to="/registrieren">
                  {{ $t("tax") }}
                </router-link>
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="SET_LOGOUT">{{
                $t("logout")
              }}</b-dropdown-item>
            </b-dropdown>
            <div class="d-flex" v-else>
              <router-link to="/anmeldung">
                <button class="blue-button login-mobile">
                  {{ $t("login") }}
                </button>
              </router-link>
              <router-link to="/registrieren">
                <button class="blue-button">
                  {{ $t("register") }}
                </button>
              </router-link>
            </div>
            <b-dropdown v-model="selected" class="lang-dropdown">
              <template #button-content>
                <img
                  :src="require(`../../assets/img/icon/${selected}.png`)"
                  alt=""
                />
              </template>
              <b-dropdown-item
                :value="item.value"
                v-for="(item, index) in options"
                :key="index"
                @click="onChange(item.value)"
              >
                <img
                  :src="require(`../../assets/img/icon/${item.text}.png`)"
                  alt=""
                />
              </b-dropdown-item>
            </b-dropdown>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- Navbar End -->
</template>
<script>
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
  ArrowRightCircleIcon,
} from "vue-feather-icons";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    navbarcolor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["activeUser"]),
  },
  components: {
    FacebookIcon,
    TwitterIcon,
    InstagramIcon,
    LinkedinIcon,
    ArrowRightCircleIcon,
  },
  data() {
    return {
      selected: "tr",
      options: [
        { value: "en", text: "en" },
        { value: "tr", text: "tr" },
        { value: "de", text: "de" },
      ],
    };
  },
  methods: {
    /**
     * Toggle menu
     */
    ...mapActions(["SET_LOGOUT_ACTION"]),
    toggleMenu() {
      document.getElementById("navbarCollapse").classList.toggle("show");
      document.getElementById("navbar-button").classList.toggle("show");
    },
    onChange(val) {
      this.$i18n.locale = val;
      this.selected = val;
      localStorage.setItem("lang", val);
    },
    selectLang() {
      const lang = localStorage.getItem("lang");

      if (lang) {
        this.selected = lang;
      } else {
        this.selected = "tr";
      }
    },
    SET_LOGOUT() {
      this.SET_LOGOUT_ACTION();
      // this.$router.push("/anmeldung");
    },
  },
  mounted: () => {
    window.onscroll = function () {
      onwindowScroll();
    };
    var navbar = document.getElementById("navbar");
    function onwindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    }
  },
  created() {
    this.selectLang();
  },
};
</script>
<style lang="scss">
.show {
  .mdi-menu::before {
    content: "\00d7";
  }
}

.lang-dropdown {
  .btn {
    padding: 12px 0px !important;
  }
}
</style>